
import '../Faq/style.scss';
import { useState } from 'react';
import { FaqLists } from './FaqLists';



function Faq() {
  const [addShowModal, setAddShowModal] = useState(false);

  const [whichTab, setWhichTab] = useState("user");
  const changeTab = (url) => {
    setWhichTab(url);
  };

  const addClick = () => {
    setAddShowModal(true);
  };
  return (
    <div className='faq_page_main'>
      <h4 className='title'>FAQ</h4>
      <div className='faq_lists_main'>
        <div className="row">
          <div className="col-md-6">
            <ul className="nav nav-tabs custom-tabs">
              <li className="nav-item">
                <button
                  className={`nav-link ${whichTab === "user" ? "active" : null
                    }`}
                  onClick={() => changeTab("user")}
                >
                  User
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${whichTab === "creator" ? "active" : null
                    }`}
                  onClick={() => changeTab("creator")}
                >
                  Creator
                </button>
              </li>
            </ul>
          </div>
          <div className="col-md-6 text-end">
            <button
              className="btn btn-sm btn-primary text-white f-14 fw-600"
              onClick={addClick}
            >
              Add New
            </button>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div className="tab-content bg-white mt-3">
              <div className="tab-pane active">
                {whichTab === "user" && (
                  <FaqLists
                    whichTab="user"
                    addShowModal={addShowModal}
                    setAddShowModal={setAddShowModal}
                  />
                )}
                {whichTab === "creator" && (
                  <FaqLists
                    whichTab="creator"
                    addShowModal={addShowModal}
                    setAddShowModal={setAddShowModal}

                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;